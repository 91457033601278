const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_API_URL_PROD
    : "http://localhost:4000";

const APP_URL =
  process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_URL_PROD
    : "http://localhost:3000";

const APP_ADMIN_URL =
  process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_ADMINURL_PROD
    : "http://localhost:3005/";
module.exports = {
  API_ENDPOINT_URL,
  APP_URL,
  APP_ADMIN_URL
};
