import React, { useState, useEffect } from "react";
import {
  Container,
  Collapse,
  NavbarToggler,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

import darkLogo from "../../assets/images/logo-dark.png";
import lightLogo from "../../assets/images/logo-light.png";
import profileImage from "../../assets/images/profile.jpg";
import logout from "../../pages/validations/logoutUser";
import Cookies from "js-cookie";
const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [home, setHome] = useState(false);
  const [company, setCompany] = useState(false);
  const [pages, setPages] = useState(false);
  const [blog, setBlog] = useState(false);
  const [employer, setEmployer] = useState(false);

  //Notification Dropdown
  const [notification, setNotification] = useState(false);
  const dropDownnotification = () => setNotification((prevState) => !prevState);

  //user Profile Dropdown
  const [userProfile, setUserProfile] = useState(false);
  const dropDownuserprofile = () => setUserProfile((prevState) => !prevState);

  //scroll navbar
  const [navClass, setnavClass] = useState("nav-sticky");

  useEffect(() => {
    //window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = window.pageYOffset;
    if (scrollup > 0) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  //menu activation
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    var matchingMenuItem = null;
    var ul = document.getElementById("navbarCollapse");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement.parentElement.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <nav
        className={"navbar navbar-expand-lg sticky fixed-top p-0 sticky-top"}
        id="navigation"
      >
        <Container fluid className="custom-container">
          <Link className="navbar-brand text-dark fw-bold me-auto" to="/">
            <img src={darkLogo} height="90" alt="" className="logo-dark" />
            <img src={lightLogo} height="90" alt="" className="logo-light" />
          </Link>
          <div>
            <NavbarToggler
              className="me-3"
              type="button"
              onClick={() => toggle()}
            >
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
          </div>
          <Collapse
            isOpen={isOpen}
            className="navbar-collapse"
            id="navbarCollapse"
          >
            <ul className="navbar-nav mx-auto navbar-center">
              <NavItem className="dropdown dropdown-hover">
                {/* <NavLink to="/layout2" id="homedrop" className="arrow-none" onClick={() => setHome(!home)}> */}
                <NavLink href="/" id="homedrop" className="arrow-none">
                  Home
                </NavLink>
              </NavItem>
              <NavItem className="dropdown dropdown-hover">
                <NavLink href="/aboutus" id="jobsdropdown" role="button">
                  About Us
                </NavLink>
              </NavItem>
              {/* <NavItem className="dropdown dropdown-hover">
                <NavLink href="/advertisement" id="jobsdropdown" role="button">
                  Advertise with us
                </NavLink>
              </NavItem> */}
              {/* <NavItem className="dropdown dropdown-hover">
                <NavLink href="/team" id="jobsdropdown" role="button">
                  Team
                </NavLink>
              </NavItem> */}
              <NavItem className="dropdown dropdown-hover">
                {/* <NavLink to="/#" id="productdropdown" role="button" data-bs-toggle="dropdown" onClick={() => setBlog(!blog)}> */}
                <NavLink
                  href="/alljobs"
                  id="productdropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Jobs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact" className="nav-link" to="#">
                  Contact us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/employerBenefits" className="nav-link" to="#">
                  For Employer
                </NavLink>
              </NavItem>
              {/* <NavItem className="dropdown dropdown-hover">
                {!Cookies.get("auth") && (
                  <NavLink to="/#" id="jobsdropdown" role="button">
                    For Employer
                  </NavLink>
                )}
                <ul
                  className={classname("dropdown-menu dropdown-menu-center", {
                    show: employer,
                  })}
                  aria-labelledby="jobsdropdown"
                >
                  <li>
                    <Link className="dropdown-item" to="/employersignup">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </NavItem>*/}
            </ul>

            <ul className="header-menu list-inline d-flex align-items-center mb-0">
              <div><span className="d-none d-md-inline-block fw-medium" style={{ marginRight: '15px' }}>
                Hi, {Cookies.get("user") || "Guest"}
              </span>
              </div>
              <div>
                <Dropdown
                  onClick={() => setUserProfile(!userProfile)}
                  isOpen={userProfile}
                  toggle={dropDownuserprofile}
                  className="list-inline-item"
                >  {Cookies.get("auth") && (
                  <DropdownToggle
                    to="#"
                    className="header-item"
                    id="userdropdown"
                    type="button"
                    tag="a"
                    aria-expanded="false"
                  >
                    <img
                      src={Cookies.get("profile_image_url") || profileImage}
                      alt="mdo"
                      width="35"
                      height="35"
                      top="50%"
                      right="50%"
                      className="rounded-circle me-1"
                    />
                  </DropdownToggle>
                )}
                  {Cookies.get("auth") && (
                    <DropdownMenu
                      className="dropdown-menu-end"
                      aria-labelledby="userdropdown"
                      end
                    >
                      <li>
                        <Link className="dropdown-item" to="/myJobs">
                          My Jobs
                        </Link>
                      </li>
                      {/* <li><Link className="dropdown-item" to="/bookmarkjobs">Bookmarks Jobs</Link></li> */}
                      <li>
                        <Link className="dropdown-item" to="/myprofile">
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={() => logout()}>
                          Logout
                        </Link>
                      </li>
                    </DropdownMenu>
                  )}
                </Dropdown>
              </div>
            </ul>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default withRouter(NavBar);
