import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Link } from "react-router-dom";

const Footer = () => {
    const footer = [
        {
            id: 1,
            title: "Company",
            menu: [
                {
                    id: 1,
                    link: "/aboutus",
                   subTitle: "About Us"
                },
                {
                    id: 2,
                    link: "/contact",
                    subTitle: "Contact Us"
                },
                // {
                //     id: 3,
                //     // link: "/services",
                //     link: "#",
                //     subTitle: "Services"
                // },
                // {
                //     id: 4,
                //     //link: "/blog",
                //     link: "#",
                //     subTitle: "Blog"
                // },
                {
                    id: 5,
                    link: "/team",
                    subTitle: "Team"
                },
                // {
                //     id: 6,
                //     // link: "/pricing",
                //     link: "#",
                //     subTitle: "Pricing"
                // },
            ]
        },
        {
            id: 2,
            title: "For Jobs",
            menu: [
                {
                    id: 1,
                    link: "/jobscategories",
                   subTitle: "Browser Categories"
                },
                {
                    id: 2,
                    link: "/alljobs",
                    subTitle: "Browser Jobs"
                },
                // {
                //     id: 3,
                //     // link: "/jobdetails",
                //     link: "#",
                //     subTitle: "Job Details"
                // },
                // {
                //     id: 4,
                //     // link: "/bookmarkjobs",
                //     link: "#",
                //     subTitle: "Bookmark Jobs"
                // },
            ]
        },
        // {
        //     id: 3,
        //     title: "For Candidates",
        //     menu: [
        //         {
        //             id: 1,
        //             //link: "/candidatelist",
        //             link: "#",
        //             subTitle: "Candidate List"
        //         },
        //         {
        //             id: 2,
        //             // link: "/candidategrid",
        //             link: "#",
        //             subTitle: "Candidate Grid"
        //         },
        //         {
        //             id: 3,
        //             // link: "/candidatedetails",
        //             link: "#",
        //             subTitle: "Candidate Details"
        //         }
        //     ]
        // },
        {
            id: 3,
            title: "Support",
            menu: [
                // {
                //     id: 1,
                //     //   link: "/contact",
                //     link: "#",
                //     subTitle: "Help Center"
                // },
                {
                    id: 2,
                    link: "/faqs",
                    subTitle: "FAQ'S"
                },
                // {
                //     id: 3,
                //     //link: "/privacyandpolicy",
                //     link: "#",
                //     subTitle: "Privacy Policy"
                // }
            ]
        },
    ];
    // const footerIcons = [
    //     {
    //         id: 1,
    //         socialIcon: "uil uil-facebook-f",
    //     },
    //     {
    //         id: 2,
    //         socialIcon: "uil uil-linkedin-alt",
    //     },
    //     {
    //         id: 3,
    //         socialIcon: "uil uil-google",
    //     },
    //     {
    //         id: 4,
    //         socialIcon: "uil uil-twitter",
    //     }
    // ];
    return (
        <React.Fragment>
            <section className="bg-footer">
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                                <h4 className="text-white mb-4">Horn Talent</h4>
                                <p className="text-white-50">We are an online job site for companies of all types and sizes struggling to find the right candidates. Join
                                today and access top talent instantly.</p>
                                {/* <p className="text-white mt-3">Follow Us on:</p> */}
                                {/* <ul className="footer-social-menu list-inline mb-0" >
                                    {(footerIcons).map((footerIcondetails, key) => (
                                        <li className="list-inline-item" key={key}>
                                            <Link to="#"><i className={footerIcondetails.socialIcon}></i></Link>
                                        </li>
                                    ))}
                                </ul> */}
                            </div>
                        </Col>
                        {(footer).map((footerdetails, key) => (
                            <Col lg={2} xs={6} key={key}>
                                <div className="footer-item mt-4 mt-lg-0">
                                    <p className="fs-16 text-white mb-4">{footerdetails.title}</p>
                                    {(footerdetails.menu || []).map((menuInner, key) => (
                                        <ul className="list-unstyled footer-list mb-0" key={key}>
                                            <li><Link to={menuInner.link}><i className="mdi mdi-chevron-right"></i> {menuInner.subTitle}</Link></li>
                                        </ul>
                                    ))}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <div className="footer-alt">
                <Container >
                    <Row >
                        <Col lg={12}>
                            <p className="text-white-50 text-center mb-0">
                                {new Date().getFullYear()} &copy; Horn Talent - Job  Page
                                {/*  by
                                <Link to="//themesdesign.in/" target="_blank"
                                    className="text-reset text-decoration-underline">
                                    Themesdesign</Link> */}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default Footer;
