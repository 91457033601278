import Cookies from "js-cookie";
import {APP_URL} from "../../constants/constants";
function logout(){
    Cookies.remove('auth');
    Cookies.remove('t');
    Cookies.remove('user');
    Cookies.remove('page');
    Cookies.remove('email');
    Cookies.remove('id');
    Cookies.remove('profile_image_url');

    window.location.href ="/login";
}

export default logout;